import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ProductCard from '../components/productCard';
import SubscriptionBar from '../components/SubscriptionBar';

import '../utils/normalize.css';
import '../utils/css/screen.css';

const pageQuery = graphql`
  fragment Grid on MarkdownRemarkConnection {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          difficulty
          description
          locked
          unlockCode
          printFile {
            id
            publicURL
            name
          }
          thumb {
            childImageSharp {
              fluid(maxWidth: 280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          finished {
            childImageSharp {
              fluid(maxWidth: 280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
  query {
    site {
      siteMetadata {
        title
        description
        phoneNumber
      }
    }
    beginnerGrids: allMarkdownRemark(
      sort: { fields: [frontmatter___order, frontmatter___title], order: [ASC, DESC] },
      filter: {
          fileAbsolutePath: {regex : "\/grids/"},
          frontmatter: { difficulty: { eq: "Beginner" } }
        },
        limit: 99,
    ) {
      ...Grid
    }
    intermediateGrids: allMarkdownRemark(
      sort: { fields: [frontmatter___order, frontmatter___title], order: [ASC, DESC] },
      filter: {
          fileAbsolutePath: {regex : "\/grids/"},
          frontmatter: { difficulty: { eq: "Intermediate" } }
        },
        limit: 99,
    ) {
      ...Grid
    }
    advancedGrids: allMarkdownRemark(
      sort: { fields: [frontmatter___order, frontmatter___title], order: [ASC, DESC] },
      filter: {
          fileAbsolutePath: {regex : "\/grids/"},
          frontmatter: { difficulty: { eq: "Advanced" } }
        },
        limit: 99,
    ) {
      ...Grid
    }
    otherGrids: allMarkdownRemark(
      sort: { fields: [frontmatter___order, frontmatter___title], order: [ASC, DESC] },
      filter: {
          fileAbsolutePath: {regex : "\/grids/"},
          frontmatter: { difficulty: { eq: "Other" } }
        },
        limit: 99,
    ) {
      ...Grid
    }
  }
`;

const TemplatesPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;
  const {
    beginnerGrids,
    intermediateGrids,
    advancedGrids,
    otherGrids,
  } = data;
  let keyCounter = 0;

  const url = location.href ? location.href : '';

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Templates"
        keywords={['animation', 'grids', 'templates', 'print', 'pdf']}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h1>Templates</h1>
          <p className="home-page-excerpt">
            Tasty treats for your printer.
          </p>
          <h2>Starters</h2>
          <div className="row">
            {beginnerGrids.edges.map(({ node }) => {
              const grid = node.frontmatter;
              keyCounter += 1;
              return (
                <div
                  className="col"
                  key={keyCounter}
                >
                  <ProductCard {...grid} link={`/templates${node.fields.slug}`} />
                </div>
              );
            })}
          </div>
          <h2>Intermediate</h2>
          <div className="row">
            {intermediateGrids.edges.map(({ node }) => {
              const grid = node.frontmatter;
              keyCounter += 1;
              return (
                <div
                  className="col"
                  key={keyCounter}
                >
                  <ProductCard {...grid} link={`/templates${node.fields.slug}`} />
                </div>
              );
            })}
          </div>
          <h2>Advanced</h2>
          <div className="row">
            {advancedGrids.edges.map(({ node }) => {
              const grid = node.frontmatter;
              keyCounter += 1;
              return (
                <div
                  className="col"
                  key={keyCounter}
                >
                  <ProductCard {...grid} link={`/templates${node.fields.slug}`} />
                </div>
              );
            })}
          </div>
          <h2>Other</h2>
          <div className="row">
            {otherGrids.edges.map(({ node }) => {
              const grid = node.frontmatter;
              keyCounter += 1;
              return (
                <div
                  className="col"
                  key={keyCounter}
                >
                  <ProductCard {...grid} link={`/templates${node.fields.slug}`} />
                </div>
              );
            })}
          </div>
        </div>
        <SubscriptionBar
          prompt="Subscribe for notifications when new templates are&nbsp;available."
          subscriptionId="animation-grids-subscribe"
          referenceUrl={url}
        />
      </article>
    </Layout>
  );
};

export default props => (
  <StaticQuery
    query={pageQuery}
    render={data => (
      <TemplatesPage location={props.location} data={data} {...props} />
    )}
  />
);
